// function to show the details of plan and billing in show more section

export function usesDetails(
  planCode,
  planStatus,
  callDuration,
  numberOfBots,
  subscriptionEndDate,
  pakaData,
  whatsApp = false,
  monthlyMessageCount = 0
) {
  let usesDetail = {};
  let extraDuration = 0;
  let extraDurationCharge = 0.0;
  let extraBots = 0;
  let extraBotsCharge = 0;
  let whatsAppIntegrationCharge = 0;
  let extraWhatsAppMessageCharge = 0;
  const today = new Date();
  const endDate = new Date(subscriptionEndDate);
  usesDetail.planType = "Please first subscribe a Plan";
  usesDetail.planTypeValue = "Advanced plan Recommended";
  usesDetail.planDuration = 0;
  usesDetail.whatsAppLimit = 0;
  usesDetail.whatsAppMessages = "included";
  usesDetail.whatsCharge = 0;
  usesDetail.additionalWhatsAppMsgCharges = 0;
  usesDetail.planDurationValue = "included";
  usesDetail.noOfBots = "included";
  usesDetail.noOfBotsValue = "0 Paka AI Bot";
  usesDetail.additionalCallMin = "For Additional call minutes you have to pay";
  usesDetail.additionalCallMinValue = "just 0.15$/min";
  usesDetail.additionalBots = "For Additional Paka AI Bot";
  usesDetail.additionalBotsValue = "0";
  usesDetail.totalCharges = "Total for a month";
  usesDetail.totalChargesValue = "0";

  if (planCode === "852658" && (planStatus === "active" || today <= endDate)) {
    let planData = pakaData?.basicPlan?.details;
    if (Number(callDuration) > (Number(planData?.callDurationLimit) || 40)) {
      extraDuration =
        Number(callDuration) - (Number(planData?.callDurationLimit) || 40);
      extraDurationCharge = Number(
        extraDuration * (Number(planData?.additionalCallPricePerMinute) || 0.2)
      )?.toFixed(2);
    }
    if (Number(numberOfBots) > 1) {
      extraBots = Number(numberOfBots) - 1;
      extraBotsCharge = Number(
        extraBots * (Number(planData?.additionalPerBotPrice) || 5.0)
      )?.toFixed(2);
    }
    if (Number(monthlyMessageCount) > Number(planData?.whatsAppMessageLimit)) {
      extraWhatsAppMessageCharge =
        (Number(monthlyMessageCount) - Number(planData?.whatsAppMessageLimit)) *
        Number(planData.additionalPerWhatsappMessagePrice);
    }
    if (whatsApp) {
      whatsAppIntegrationCharge = planData?.whatsAppIntegrationCharge || 5;
    }
    let totalCharges = Number(
      Number(planData?.price || 25) +
        Number(extraDurationCharge) +
        Number(extraBotsCharge) +
        Number(whatsAppIntegrationCharge) +
        Number(extraWhatsAppMessageCharge)
    )?.toFixed(2);
    usesDetail.planType = "Basic plan";
    usesDetail.planTypeValue = "25.00 $";
    usesDetail.planDuration = planData?.callDurationLimit || "40";
    usesDetail.whatsAppLimit = planData?.whatsAppMessageLimit || "100";
    usesDetail.whatsAppMessages = "included";
    usesDetail.whatsCharge = planData?.whatsAppIntegrationCharge || 5;
    usesDetail.additionalWhatsAppMsgCharges = extraWhatsAppMessageCharge || 0;
    usesDetail.planDurationValue = "included";
    usesDetail.noOfBots = planData?.basicPlan?.noOfBots || "1 Paka AI Bot";
    usesDetail.noOfBotsValue = "included";
    usesDetail.additionalCallMin = "Additional call minutes";
    usesDetail.additionalCallMinValue = `${extraDuration} minutes = ${extraDurationCharge} $`;
    usesDetail.additionalBots = "Additional Paka AI Bot";
    usesDetail.additionalBotsValue = `${extraBots} = ${extraBotsCharge} $`;
    usesDetail.totalCharges = "Total this month";
    usesDetail.totalChargesValue = totalCharges;
  } else if (
    planCode === "852659" &&
    (planStatus === "active" || today <= endDate)
  ) {
    let planData = pakaData?.advancedPlan?.details;
    if (Number(callDuration) > (Number(planData?.callDurationLimit) || 400)) {
      extraDuration =
        Number(callDuration) - (Number(planData?.callDurationLimit) || 400);
      extraDurationCharge = Number(
        extraDuration * (Number(planData?.additionalCallPricePerMinute) || 0.15)
      )?.toFixed(2);
    }
    if (Number(numberOfBots) > 2) {
      extraBots = Number(numberOfBots) - 2;
      extraBotsCharge = Number(
        extraBots * (Number(planData?.additionalPerBotPrice) || 3.0)
      )?.toFixed(2);
    }
    if (Number(monthlyMessageCount) > Number(planData?.whatsAppMessageLimit)) {
      extraWhatsAppMessageCharge =
        (Number(monthlyMessageCount) - Number(planData?.whatsAppMessageLimit)) *
        Number(planData.additionalPerWhatsappMessagePrice);
    }
    if (whatsApp) {
      whatsAppIntegrationCharge = planData?.whatsAppIntegrationCharge || 30;
    }
    let totalCharges = Number(
      Number(planData?.price || 90) +
        Number(extraDurationCharge) +
        Number(extraBotsCharge) +
        Number(whatsAppIntegrationCharge) +
        Number(extraWhatsAppMessageCharge)
    )?.toFixed(2);
    usesDetail.planType = "Advanced plan";
    usesDetail.planTypeValue = "90.00 $";
    usesDetail.planDuration = planData?.callDurationLimit || "400";
    usesDetail.whatsAppLimit = planData?.whatsAppMessageLimit || "1000";
    usesDetail.whatsAppMessages = "included";
    usesDetail.whatsCharge = planData?.whatsAppIntegrationCharge || 30;
    usesDetail.additionalWhatsAppMsgCharges = extraWhatsAppMessageCharge || 0;
    usesDetail.planDurationValue = "included";
    usesDetail.noOfBots = planData?.advancedPlan?.noOfBots || "2 Paka AI Bot";
    usesDetail.noOfBotsValue = "included";
    usesDetail.additionalCallMin = "Additional call minutes";
    usesDetail.additionalCallMinValue = `${extraDuration} minutes = ${extraDurationCharge} $`;
    usesDetail.additionalBots = "Additional Paka AI Bot";
    usesDetail.additionalBotsValue = `${extraBots} = ${extraBotsCharge} $`;
    usesDetail.totalCharges = "Total this month";
    usesDetail.totalChargesValue = `${totalCharges} $`;
  } else if (
    planCode === "852660" &&
    (planStatus === "active" || today <= endDate)
  ) {
    let planData = pakaData?.expertPlan?.details;
    if (Number(callDuration) > (Number(planData?.callDurationLimit) || 3500)) {
      extraDuration =
        Number(callDuration) - (Number(planData?.callDurationLimit) || 3500);
      extraDurationCharge = Number(
        extraDuration * (Number(planData?.additionalCallPricePerMinute) || 0.15)
      )?.toFixed(2);
    }
    if (Number(numberOfBots) > 3) {
      extraBots = Number(numberOfBots) - 3;
      extraBotsCharge = Number(
        extraBots * (Number(planData?.additionalPerBotPrice) || 2.0)
      )?.toFixed(2);
    }
    if (Number(monthlyMessageCount) > Number(planData?.whatsAppMessageLimit)) {
      extraWhatsAppMessageCharge =
        (Number(monthlyMessageCount) - Number(planData?.whatsAppMessageLimit)) *
        Number(planData.additionalPerWhatsappMessagePrice);
    }
    if (whatsApp) {
      whatsAppIntegrationCharge = planData?.whatsAppIntegrationCharge || 100;
    }
    let totalCharges = Number(
      Number(planData?.price || 500) +
        Number(extraDurationCharge) +
        Number(extraBotsCharge) +
        Number(whatsAppIntegrationCharge) +
        Number(extraWhatsAppMessageCharge)
    )?.toFixed(2);
    usesDetail.planType = "Expert plan";
    usesDetail.planTypeValue = "500.00 $";
    usesDetail.planDuration = planData?.callDurationLimit || "3500";
    usesDetail.whatsAppLimit = planData?.whatsAppMessageLimit || "4000";
    usesDetail.whatsAppMessages = "included";
    usesDetail.whatsCharge = planData?.whatsAppIntegrationCharge || 100;
    usesDetail.additionalWhatsAppMsgCharges = extraWhatsAppMessageCharge || 0;
    usesDetail.planDurationValue = "included";
    usesDetail.noOfBots = planData?.expertPlan?.noOfBots || "3 Paka AI Bot";
    usesDetail.noOfBotsValue = "included";
    usesDetail.additionalCallMin = "Additional call minutes";
    usesDetail.additionalCallMinValue = `${extraDuration} minutes = ${extraDurationCharge} $`;
    usesDetail.additionalBots = "Additional Paka AI Bot";
    usesDetail.additionalBotsValue = `${extraBots} = ${extraBotsCharge} $`;
    usesDetail.totalCharges = "Total this month";
    usesDetail.totalChargesValue = `${totalCharges} $`;
  }

  return usesDetail;
}

// function to show current plan and upgrade button

export function planButtonTypes(planCode, status) {
  let buttonType = {};
  if (planCode === "852658" && status === "active") {
    buttonType.basicText = "Your Plan";
    buttonType.basicActive = false;
    buttonType.advancedText = "Upgrade Plan";
    buttonType.advancedActive = true;
    buttonType.expertText = "Upgrade Plan";
    buttonType.expertActive = true;
  } else if (planCode === "852659" && status === "active") {
    buttonType.basicText = "Downgrade Plan";
    buttonType.basicActive = true;
    buttonType.advancedText = "Your Plan";
    buttonType.advancedActive = false;
    buttonType.expertText = "Upgrade Plan";
    buttonType.expertActive = true;
  } else if (planCode === "852660" && status === "active") {
    buttonType.basicText = "Downgrade Plan";
    buttonType.basicActive = true;
    buttonType.advancedText = "Downgrade Plan";
    buttonType.advancedActive = true;
    buttonType.expertText = "Your Plan";
    buttonType.expertActive = false;
  } else {
    buttonType.basicText = "Select Plan";
    buttonType.basicActive = true;
    buttonType.advancedText = "Select Plan";
    buttonType.advancedActive = true;
    buttonType.expertText = "Select Plan";
    buttonType.expertActive = true;
  }
  return buttonType;
}

export function planType(planCode, planStatus, subscriptionEndDate) {
  const today = new Date();
  const endDate = new Date(subscriptionEndDate);
  let planType = "Free Plan";
  if (planCode === "852658" && (planStatus === "active" || today <= endDate)) {
    planType = "Basic plan";
  } else if (
    planCode === "852658" &&
    (planStatus === "active" || today <= endDate)
  ) {
    planType = "Advanced plan";
  } else if (
    planCode === "852660" &&
    (planStatus === "active" || today <= endDate)
  ) {
    planType = "Expert plan";
  } else {
    planType = "Free Plan";
  }
  return planType;
}
