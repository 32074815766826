import React, { useState } from "react";
// import card from "../../assets/images/Icons/card.svg";
// import visa from "../../assets/images/Icons/visa.svg";
// import mastercard from "../../assets/images/Icons/mastercard.svg";
// import mastercard1 from "../../assets/images/Icons/mastercard1.svg";
import { AiOutlineUp } from "react-icons/ai";
import { AiOutlineDown } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
// import { callDuration } from "../../utils/helper/dashboardFunctions";
import {
  usesDetails,
  planButtonTypes,
} from "../../utils/helper/plan&billingFunctions";
import ProgressBar from "@ramonak/react-progress-bar";
// import axios from "axios";
import { ToastContainer } from "react-toastify";
import CustomModal from "../CustomModal";
import { Puff } from "react-loader-spinner";
import { setUserData } from "../../redux/AppSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import api from "../../services/axiosInstance";
import { showToast } from "../../utils/showToast";

const Plan = (props) => {
  const {
    planType,
    planInfo,
    planPrice,
    planMin,
    planRate,
    planButton,
    btnStyle,
    boxStyle,
    recStyle,
    noOfBots,
    newBotFixCharge,
    callHistoryValidity,
    messageCenter,
    appointmentScheduling,
    whatsApp,
    whatsAppIntegrationCharge,
    whatsAppMessageCharge,
    isExtend,
    setIsExtend,
    extendStyle,
    onChangePlan,
    updatePlanPopup,
    setUpdatePlanPopup,
    // btnDisable,
  } = props;
  return (
    <>
      <CustomModal isOpen={updatePlanPopup}>
        <div className="  flex flex-col items-center py-6 bg-white h-fit w-10/12 lg:w-3/5 xl:w-1/2 lg:ml-6 rounded-md shadow-dropDownBox mt-24 lg:mt-10">
          <p className=" text-primary font-medium my-2 text-xl">
            Are you sure{" "}
          </p>
          <span className=" text-center mx-8 xl:mx-28 text-gray-400">
            Upgrading and Downgrading the plan causes an additional charge by
            clicking on the "Confirm" button.
          </span>
          <button
            onClick={() => {
              onChangePlan();
            }}
            className="bg-primary hover:bg-secoundry text-[14px] text-white   rounded py-2 px-20 mt-6"
          >
            Confirm
          </button>
          <p
            onClick={() => setUpdatePlanPopup(false)}
            className=" mt-3 cursor-pointer"
          >
            Cancel
          </p>
        </div>
      </CustomModal>
      <div className={`${boxStyle} rounded-xl relative `}>
        <div
          className={`${recStyle} bg-[#FFECEB]/[0.2] rounded-md items-end absolute top-2 right-2`}
        >
          <p className={`text-[10px] text-[#FF0000] font-medium px-2 py-1`}>
            Recommended
          </p>
        </div>
        <h5 className="mt-[30px] mb-[11px]">{planType}</h5>
        <p className="text-[13px] text-[#7F7F7F] font-normal mb-[20px]">
          {planInfo}
        </p>
        <h3 className="text-[56px] font-semibold text-[#FF0066] mb-[20px]">
          {planPrice}
        </h3>
        <div className="">
          <div className="flex gap-2 mb-3">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {planMin}
            </p>
          </div>
          <div className="flex gap-2 mb-3">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {planRate}
            </p>
          </div>
          <div className="flex gap-2 mb-3">
            <p className="rounded-[100%] bg-[#E2ECFF]">
              <span className="px-1 text-primary">✓</span>
            </p>
            <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
              {noOfBots}
            </p>
          </div>
        </div>

        {isExtend ? (
          <>
            <div className="transition ease-in-out duration-300">
              <div className="flex gap-2 mb-3">
                <p className="rounded-[100%] bg-[#E2ECFF]">
                  <span className="px-1 text-primary">✓</span>
                </p>
                <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
                  {newBotFixCharge}
                </p>
              </div>
              <div className="flex gap-2 mb-3">
                <p className="rounded-[100%] bg-[#E2ECFF]">
                  <span className="px-1 text-primary">✓</span>
                </p>
                <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
                  {callHistoryValidity}
                </p>
              </div>
              <div className="flex gap-2 mb-3">
                <p className="rounded-[100%] bg-[#E2ECFF]">
                  <span className="px-1 text-primary">✓</span>
                </p>
                <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
                  {messageCenter}
                </p>
              </div>
              <div className="flex gap-2 mb-3">
                <p className="rounded-[100%] bg-[#E2ECFF]">
                  <span className="px-1 text-primary">✓</span>
                </p>
                <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
                  {appointmentScheduling}
                </p>
              </div>
              <div className="flex gap-2 mb-3">
                <p className="rounded-[100%] bg-[#E2ECFF]">
                  <span className="px-1 text-primary">✓</span>
                </p>
                <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
                  {whatsApp}
                </p>
              </div>
              <div className="flex gap-2 mb-3">
                <p className="">
                  <span className="px-1 text-inherit">-</span>
                </p>
                <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
                  {whatsAppIntegrationCharge}
                </p>
              </div>
              <div className="flex gap-2 mb-3">
                <p className="">
                  <span className="px-1 text-inherit">-</span>
                </p>
                <p className="text-[16px] md:text-[14px] lg:text-[14px] xl:text-[16px]">
                  {whatsAppMessageCharge}
                </p>
              </div>
            </div>
            <div className={`${extendStyle}`}>
              <hr className="mt-5" />
              <div className="flex justify-center mb-5">
                {/* <MdExpandLess /> */}
                <AiOutlineUp
                  className=" cursor-pointer "
                  onClick={() => setIsExtend(!isExtend)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <hr className="mt-5" />
            <div className="flex justify-center mb-5">
              {/* <MdExpandLess /> */}
              <AiOutlineDown
                className=" cursor-pointer "
                onClick={() => setIsExtend(!isExtend)}
              />
            </div>
          </>
        )}
        <button
          className={`${btnStyle}  border text-[16px] px-6 my-1 rounded-lg w-full h-11 font-semibold mb-[28px]`}
          //onClick={props.openCheckout}
          onClick={() => {
            setUpdatePlanPopup(true);
          }}
          // disabled={btnDisable}
        >
          {planButton}
        </button>
      </div>
    </>
  );
};

const PlanAndBilling = () => {
  const pakaData = useSelector((state) => state.content.pakaData)?.settingPlan;
  const userData = useSelector((state) => state.app.userData);
  const tabInfo = [
    { title: "Basic" },
    { title: "Advanced" },
    { title: "Expert" },
  ];
  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabInfo[0]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [isExtend, setIsExtend] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isShowMoreInfo, setIsShowMoreInfo] = useState(false);
  const dispatch = useDispatch();
  const [updatePlanPopupB, setUpdatePlanPopupB] = useState(false);
  const [updatePlanPopupA, setUpdatePlanPopupA] = useState(false);
  const [updatePlanPopupE, setUpdatePlanPopupE] = useState(false);

  const callDurationInMin = Math.ceil(userData?.monthlyBotCallDuration / 60);

  const usesDetail = usesDetails(
    userData?.subscriptionPlanId,
    userData?.subscriptionStatus,
    callDurationInMin,
    userData?.activeBotInfo?.length,
    userData?.subscriptionEndDate,
    pakaData,
    userData?.whatsApp,
    userData?.monthlyMessageCount
  );
  const planButtonType = planButtonTypes(
    userData?.subscriptionPlanId,
    userData?.subscriptionStatus
  );
  const handleClick = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const navigate = useNavigate();

  const requestCancelSubscription = async (status) => {
    setLoader(true);
    const payload = {
      user_id: userData?.id,
      requestCancelSubscription: status,
    };
    if (userData?.subscriptionStatus === "active") {
      api
        .post(`/auth/update_user_data`, payload)
        .then((response) => {
          dispatch(
            setUserData({
              requestCancelSubscription: status,
            })
          );
          setShowModel(false);
          setShowConfirmModel(false);
          setLoader(false);
          showToast("success", "Your request proceed Successfully", {
            style: { backgroundColor: "green" },
            toastId: "requestCancelSubscription",
            containerId: "PlanAndBilling",
          });
        })
        .catch((error) => {
          setShowModel(false);
          setShowConfirmModel(false);
          setLoader(false);
          showToast(
            "warn",
            "Some error in proceeding your request, please try again later",
            {
              style: { backgroundColor: "red" },
              toastId: "requestCancelSubscription",
              containerId: "PlanAndBilling",
            }
          );
          console.log(error);
        });
    } else if (userData?.subscriptionStatus !== "active") {
      setShowModel(false);
      setShowConfirmModel(false);
      setLoader(false);
      showToast(
        "warn",
        "Please, Create a subscription plan to perform other operation",
        {
          style: { backgroundColor: "red" },
          toastId: "requestCancelSubscription",
          containerId: "PlanAndBilling",
        }
      );
    }
  };

  // const handleCancelSubscription = async () => {
  //   setLoader(true);
  //   if (userData?.subscriptionStatus === "active") {
  //     await axios
  //       .post(`${process.env.REACT_APP_BASE_URL}/cancel_subscription`, {
  //         subscription_id: userData?.subscriptionId,
  //         _id: userData?.id,
  //       })
  //       .then((response) => {
  //         // dispatch(
  //         //   setUserData({
  //         //     subscriptionPlanId:
  //         //       response?.data?.subscriptionStatus?.toString(),
  //         //   })
  //         // );
  //         dispatch(setUserData(response?.data?.userData));
  //         setLoader(false);
  //         setShowModel(false);
  //         setShowConfirmModel(false);
  //         toast.success("Cancel Subscription proceed Successfully", {
  //           theme: "colored",
  //           position: "top-center",
  //           autoClose: 3000,
  //           progress: false,
  //           hideProgressBar: true,
  //           style: { backgroundColor: "green" },
  //         });
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         toast.warn("Some error in Updating Plan, please try again later", {
  //           theme: "colored",
  //           position: "top-center",
  //           autoClose: 3000,
  //           progress: false,
  //           hideProgressBar: true,
  //         });
  //         console.log(error);
  //       });
  //   } else if (userData?.subscriptionStatus !== "active") {
  //     toast.warn(
  //       "Please, Create a subscription plan to perform other operation",
  //       {
  //         theme: "colored",
  //         position: "top-center",
  //         autoClose: 3000,
  //         progress: false,
  //         hideProgressBar: true,
  //       }
  //     );
  //   }
  // };

  const handlePlanChange = async (newPlanId) => {
    if (
      Number(userData?.subscriptionPlanId) !== newPlanId &&
      userData?.subscriptionStatus === "active"
    ) {
      setLoader(true);
      setUpdatePlanPopupB(false);
      setUpdatePlanPopupA(false);
      setUpdatePlanPopupE(false);
      api
        .post(`/payment/update_plan`, {
          user_id: userData?.id,
          subscription_id: userData?.subscriptionId,
          plan_id: newPlanId,
        })
        .then((response) => {
          setLoader(false);
          dispatch(
            setUserData({
              subscriptionPlanId:
                response?.data?.planData?.plan_id?.toString() || newPlanId,
            })
          );
          showToast("success", "Selected Plan Updated Successfully", {
            style: { backgroundColor: "green" },
            toastId: "updatePlan",
            containerId: "PlanAndBilling",
          });
        })
        .catch((error) => {
          setLoader(false);
          showToast(
            "warn",
            "Some error in proceeding your request, please try again later",
            {
              style: { backgroundColor: "red" },
              toastId: "updatePlan",
              containerId: "PlanAndBilling",
            }
          );
          console.log(error);
        });
    } else if (
      Number(userData?.subscriptionPlanId) === newPlanId &&
      userData?.subscriptionStatus === "active"
    ) {
      setUpdatePlanPopupB(false);
      setUpdatePlanPopupA(false);
      setUpdatePlanPopupE(false);
      showToast("warn", "You already have this subscription plan", {
        style: { backgroundColor: "red" },
        toastId: "updatePlan",
        containerId: "PlanAndBilling",
      });
    } else {
      setUpdatePlanPopupB(false);
      setUpdatePlanPopupA(false);
      setUpdatePlanPopupE(false);
      navigate("/subscription");
      showToast("warn", "Please, Create a subscription plan", {
        style: { backgroundColor: "red" },
        toastId: "updatePlan",
        containerId: "PlanAndBilling",
      });
    }
  };

  return (
    <>
      {/* for laptop or large screen */}
      <CustomModal isOpen={loader}>
        <div className=" flex items-center justify-center">
          <div className=" bg-white p-8 h-fit w-fit rounded-md">
            <Puff
              height="100"
              width="100"
              radius={1}
              color="#FF0066"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      </CustomModal>

      <ToastContainer containerId="PlanAndBilling" />
      <CustomModal isOpen={showModel}>
        {showConfirmModel ? (
          <div className="flex flex-col items-center py-6 bg-white h-fit w-10/12 lg:w-3/5 xl:w-1/2 lg:ml-6 rounded-md shadow-dropDownBox mt-24 lg:mt-10">
            <p className=" text-primary font-medium my-2 text-xl px-2 text-center">
              We're sorry to see you go! 😢
            </p>
            <span className=" text-center mx-8 xl:mx-28 text-gray-400">
              You can continue to utilize our services until the end of your
              subscription period{" "}
              <span className="font-medium">
                {dayjs(userData.subscriptionEndDate).format("MMM D, YYYY")}
              </span>
              . If you ever change your mind, you can easily revert cancel
              subscription request.
            </span>
            <button
              onClick={() => {
                // onChangePlan();
                requestCancelSubscription(true);
              }}
              className="bg-secoundry text-[14px] font-medium text-white rounded py-2 px-12 mt-6"
            >
              Cancel before Renew
            </button>
            <p
              onClick={() => {
                setShowModel(false);
                setShowConfirmModel(false);
              }}
              className=" mt-3 cursor-pointer"
            >
              Cancel
            </p>
          </div>
        ) : (
          <div className="  flex flex-col items-center py-6 bg-white h-fit w-10/12 lg:w-3/5 xl:w-1/2 lg:ml-6 rounded-md shadow-dropDownBox mt-24 lg:mt-10">
            {userData?.requestCancelSubscription ? (
              <>
                <p className=" text-primary font-medium my-2 text-xl px-2 text-center">
                  We're thrilled to have you back! 😊
                </p>
                <span className=" text-center mx-8 xl:mx-28 text-gray-400">
                  You can revert cancel subscription request anytime before{" "}
                  <span className="font-medium">
                    {dayjs(userData?.subscriptionEndDate)?.format(
                      "MMM D, YYYY"
                    )}
                  </span>{" "}
                  and continue enjoying our services. Welcome back!
                </span>
                <button
                  onClick={() => {
                    // onChangePlan();
                    requestCancelSubscription(false);
                  }}
                  className="bg-green-500 disable text-[14px] font-medium text-white rounded py-2 px-12 mt-6 mx-4"
                >
                  Revert Cancel subscription Request
                </button>
              </>
            ) : (
              <>
                <p className=" text-primary font-medium my-2 text-xl">
                  Are you sure{" "}
                </p>
                <span className=" text-center mx-8 xl:mx-28 text-gray-400">
                  While we continuously enhance our system to provide a better
                  user experience and more effective bot responses, you can
                  still cancel your subscription by clicking the “Confirm”
                  button.
                </span>
                <button
                  onClick={() => {
                    setShowConfirmModel(true);
                  }}
                  className="bg-secoundry text-[14px] font-medium text-white rounded py-2 px-20 mt-6"
                >
                  Confirm
                </button>
              </>
            )}
            <p
              onClick={() => setShowModel(false)}
              className=" mt-3 cursor-pointer"
            >
              Cancel
            </p>
          </div>
        )}
      </CustomModal>
      <div className="hidden md:block">
        <div className="mt-2 flex justify-between">
          <div className="w-[100%] pr-[32px]">
            <div className="flex">
              <h5 className="text-primary text-[18px] font-medium">
                {usesDetail?.planType}
              </h5>
              <p className="text-[#10C300] text-[11px] font-normal">monthly</p>
            </div>
            <div className="flex justify-between my-3">
              <h4 className="text-2xl font-bold text-[#FF0066]">
                ${usesDetail?.totalChargesValue}
              </h4>
              <h4 className="text-2xl font-bold mr-10">
                {userData?.activeBotInfo.length} Bot
              </h4>
            </div>
            <div className="">
              <div className="w-3/4">
                <p className="text-[15px] text-[#212B36]">
                  Voice Call : {callDurationInMin} of {usesDetail?.planDuration}{" "}
                  minutes exhausted
                </p>
              </div>
              <div className="mt-1">
                <ProgressBar
                  completed={
                    (callDurationInMin * 100) / usesDetail?.planDuration
                  }
                  bgColor="#000032"
                  height="12px"
                  baseBgColor="#D9D9D9"
                  labelAlignment="center"
                  labelSize="11px"
                  className="border rounded-lg"
                />
              </div>
            </div>
            <div className="mt-2">
              <div className="w-3/4">
                <p className="text-[15px] text-[#212B36]">
                  WhatsApp Messages : {userData?.monthlyMessageCount} of{" "}
                  {usesDetail?.whatsAppLimit} messages exhausted
                </p>
              </div>
              <div className="mt-1">
                <ProgressBar
                  completed={
                    (userData?.monthlyMessageCount * 100) /
                    usesDetail?.whatsAppLimit
                  }
                  bgColor="#000032"
                  height="12px"
                  baseBgColor="#D9D9D9"
                  labelAlignment="center"
                  labelSize="11px"
                  className="border rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>

        {isShowMore ? (
          <>
            <div className="text-[15px] mt-2 text-primary">
              <p>
                <span className=" font-normal">{usesDetail?.planType}</span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.planTypeValue}
                </span>
              </p>
              <p>
                <span className=" font-normal">
                  {usesDetail?.planDuration} minutes call
                </span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.planDurationValue}
                </span>
              </p>
              <p>
                <span className=" font-normal">{usesDetail?.noOfBots}</span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.noOfBotsValue}
                </span>
              </p>
              <p>
                <span className=" font-normal">
                  {usesDetail?.additionalCallMin}
                </span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.additionalCallMinValue}
                </span>
              </p>
              <p>
                <span className=" font-normal">
                  {usesDetail?.additionalBots}
                </span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.additionalBotsValue}
                </span>
              </p>
              <p>
                <span className=" font-normal">{"WhatsApp Messages"}</span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.whatsAppMessages}
                </span>
              </p>
              <p>
                <span className=" font-normal">{"WhatsApp Pack Charge"}</span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.whatsCharge} {" $"}
                </span>
              </p>
              <p>
                <span className=" font-normal">
                  {"Extra WhatsApp Messages Charge"}
                </span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.additionalWhatsAppMsgCharges} {" $"}
                </span>
              </p>
              <p>
                <span className=" font-semibold">
                  {usesDetail?.totalCharges}
                </span>
                {" : "}
                <span className=" font-semibold">
                  {usesDetail?.totalChargesValue} {" $"}
                </span>
              </p>
            </div>
            <div className="">
              <div className="flex justify-center text-[#7F7F7F]">
                <AiOutlineUp
                  className=" cursor-pointer "
                  onClick={() => setIsShowMore(false)}
                />
              </div>
              <div className="flex justify-center mb-2 text-[#7F7F7F] text-[13px]">
                <p>Show Less</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center mt-2 text-[#7F7F7F] text-[13px]">
              <p>Show More</p>
            </div>
            <div className="flex justify-center mb-2 text-[#7F7F7F]">
              <AiOutlineDown
                className=" cursor-pointer "
                onClick={() => setIsShowMore(true)}
              />
            </div>
          </>
        )}

        <hr className="mb-8" />
        <h5 className="text-lg font-medium mb-5">All Plans</h5>
        <div className="flex gap-1 lg:gap-2 xl:gap-8 my-4">
          <Plan
            planType={"Basic"}
            planInfo={pakaData?.basicPlan?.subHeading}
            planPrice={pakaData?.basicPlan?.price}
            planMin={pakaData?.basicPlan?.callDuration}
            planRate={pakaData?.basicPlan?.overDurationCharges}
            noOfBots={pakaData?.basicPlan?.noOfBots}
            newBotFixCharge={pakaData?.basicPlan?.newBotFixCharge}
            callHistoryValidity={pakaData?.basicPlan?.callHistoryValidity}
            messageCenter={pakaData?.basicPlan?.messageCenter}
            appointmentScheduling={pakaData?.basicPlan?.appointmentScheduling}
            whatsApp={pakaData?.basicPlan?.whatsApp}
            whatsAppIntegrationCharge={
              pakaData?.basicPlan?.whatsAppIntegrationCharge
            }
            whatsAppMessageCharge={pakaData?.basicPlan?.whatsAppMessageCharge}
            planButton={planButtonType?.basicText}
            btnStyle={
              planButtonType?.basicActive
                ? `bg-primary text-white transition-all duration-300 ease-in-out hover:bg-secoundry hover:text-white hover:border-secoundry`
                : `bg-[#E7E7E7] text-[#7F7F7F] opacity-50 cursor-not-allowed`
            }
            recStyle={`invisible`}
            boxStyle={`border md:px-2 lg:px-5 xl:px-7 w-1/3`}
            isExtend={isExtend}
            setIsExtend={setIsExtend}
            onChangePlan={() => handlePlanChange(852658)}
            updatePlanPopup={updatePlanPopupB}
            setUpdatePlanPopup={setUpdatePlanPopupB}
          />
          <Plan
            planType={"Advanced"}
            planInfo={pakaData?.advancedPlan?.subHeading}
            planPrice={pakaData?.advancedPlan?.price}
            planMin={pakaData?.advancedPlan?.callDuration}
            planRate={pakaData?.advancedPlan?.overDurationCharges}
            noOfBots={pakaData?.advancedPlan?.noOfBots}
            newBotFixCharge={pakaData?.advancedPlan?.newBotFixCharge}
            callHistoryValidity={pakaData?.advancedPlan?.callHistoryValidity}
            messageCenter={pakaData?.advancedPlan?.messageCenter}
            appointmentScheduling={
              pakaData?.advancedPlan?.appointmentScheduling
            }
            whatsApp={pakaData?.advancedPlan?.whatsApp}
            whatsAppIntegrationCharge={
              pakaData?.advancedPlan?.whatsAppIntegrationCharge
            }
            whatsAppMessageCharge={
              pakaData?.advancedPlan?.whatsAppMessageCharge
            }
            planButton={planButtonType?.advancedText}
            boxStyle={`bg-primary text-white border md:px-2 lg:px-5 xl:px-7 w-1/3`}
            btnStyle={
              planButtonType?.advancedActive
                ? `bg-white text-primary transition-all duration-300 ease-in-out hover:bg-secoundry hover:text-white hover:border-secoundry`
                : `bg-[#E7E7E7] text-[#7F7F7F] opacity-50 cursor-not-allowed`
            }
            isExtend={isExtend}
            setIsExtend={setIsExtend}
            onChangePlan={() => handlePlanChange(852659)}
            updatePlanPopup={updatePlanPopupA}
            setUpdatePlanPopup={setUpdatePlanPopupA}
          />
          <Plan
            planType={"Expert"}
            planInfo={pakaData?.expertPlan?.subHeading}
            planPrice={pakaData?.expertPlan?.price}
            planMin={pakaData?.expertPlan?.callDuration}
            planRate={pakaData?.expertPlan?.overDurationCharges}
            noOfBots={pakaData?.expertPlan?.noOfBots}
            newBotFixCharge={pakaData?.expertPlan?.newBotFixCharge}
            callHistoryValidity={pakaData?.expertPlan?.callHistoryValidity}
            messageCenter={pakaData?.expertPlan?.messageCenter}
            appointmentScheduling={pakaData?.expertPlan?.appointmentScheduling}
            whatsApp={pakaData?.expertPlan?.whatsApp}
            whatsAppIntegrationCharge={
              pakaData?.expertPlan?.whatsAppIntegrationCharge
            }
            whatsAppMessageCharge={pakaData?.expertPlan?.whatsAppMessageCharge}
            planButton={planButtonType?.expertText}
            recStyle={`invisible`}
            btnStyle={
              planButtonType?.expertActive
                ? `bg-primary text-white transition-all duration-300 ease-in-out hover:bg-secoundry hover:text-white hover:border-secoundry`
                : `bg-[#E7E7E7] text-[#7F7F7F] opacity-50 cursor-not-allowed`
            }
            boxStyle={`border md:px-2 lg:px-5 xl:px-7 w-1/3 `}
            isExtend={isExtend}
            setIsExtend={setIsExtend}
            onChangePlan={() => handlePlanChange(852660)}
            updatePlanPopup={updatePlanPopupE}
            setUpdatePlanPopup={setUpdatePlanPopupE}
          />
        </div>
        <hr className="mt-14 mb-8" />
        <div
          onClick={() => {
            setIsShowMoreInfo(!isShowMoreInfo);
          }}
          className="flex justify-between"
        >
          <h5 className="text-lg font-medium mb-5 cursor-pointer">
            Payment & Subscription
          </h5>
          <div className="flex gap-2 mt-2 cursor-pointer">
            {!isShowMoreInfo && <AiOutlineDown />}
            {isShowMoreInfo && <AiOutlineUp />}
          </div>
        </div>
        {userData?.subscriptionStatus === "active" && isShowMoreInfo && (
          <div className="flex flex-col mb-14">
            <div className="flex justify-center my-5">
              <a href={userData?.subscriptionUpdateUrl}>Edit payment method</a>
            </div>
            <div
              onClick={() => setShowModel(true)}
              className="flex justify-center mb-3 text-secoundry cursor-pointer"
            >
              {/* {userData?.requestCancelSubscription
                ? "Re-Subscribe"
                : "Cancel Subscription"} */}
              {"Cancel Subscription"}
            </div>
          </div>
        )}
      </div>

      {/* for mobile and tabs */}

      <div className="md:hidden">
        <div className="mb-10 mt-5">
          <div className="flex justify-between">
            <div className="flex">
              <h5 className="text-primary text-[18px] font-medium">
                {usesDetail?.planType}
              </h5>
              <p className="text-[#10C300] text-[11px] font-normal">monthly</p>
            </div>
          </div>

          <div>
            <div className="flex justify-between my-3">
              <h4 className="text-2xl font-bold text-[#FF0066]">
                ${usesDetail?.totalChargesValue}
              </h4>
              <h4 className="text-2xl font-bold">
                {userData?.activeBotInfo.length} Bot
              </h4>
            </div>
            <p className="text-[15px] text-[#212B36]">
              {callDurationInMin} of {usesDetail?.planDuration} minutes
              exhausted
            </p>
            <div className="mt-1">
              <ProgressBar
                completed={(callDurationInMin * 100) / usesDetail?.planDuration}
                bgColor="#000032"
                height="12px"
                baseBgColor="#D9D9D9"
                labelAlignment="center"
                labelSize="11px"
                className="border rounded-lg"
              />
            </div>
            <p className="mt-3 text-[15px] text-[#212B36]">
              WhatsApp Messages : {userData?.monthlyMessageCount} of{" "}
              {usesDetail?.whatsAppLimit} messages exhausted
            </p>
            <div className="mt-1">
              <ProgressBar
                completed={
                  (userData?.monthlyMessageCount * 100) /
                  usesDetail?.whatsAppLimit
                }
                bgColor="#000032"
                height="12px"
                baseBgColor="#D9D9D9"
                labelAlignment="center"
                labelSize="11px"
                className="border rounded-lg"
              />
            </div>
            <div onClick={handleClick} className="flex justify-between">
              <div></div>
              <div>
                <div className="flex gap-2 mt-2 cursor-pointer">
                  <p>more info</p>
                  {!isPopupOpen && <AiOutlineDown className="mt-1" />}
                  {isPopupOpen && <AiOutlineUp className="mt-1" />}
                </div>
              </div>
            </div>

            {isPopupOpen && (
              <div className="text-[14px] mt-2 text-primary pl-2">
                <p>
                  <span className=" font-normal">{usesDetail?.planType}</span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.planTypeValue}
                  </span>
                </p>
                <p>
                  <span className=" font-normal">
                    {usesDetail?.planDuration} minutes call
                  </span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.planDurationValue}
                  </span>
                </p>
                <p>
                  <span className=" font-normal">{usesDetail?.noOfBots}</span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.noOfBotsValue}
                  </span>
                </p>
                <p>
                  <span className=" font-normal">
                    {usesDetail?.additionalCallMin}
                  </span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.additionalCallMinValue}
                  </span>
                </p>
                <p>
                  <span className=" font-normal">
                    {usesDetail?.additionalBots}
                  </span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.additionalBotsValue}
                  </span>
                </p>
                <p>
                  <span className=" font-normal">{"WhatsApp Messages"}</span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.whatsAppMessages}
                  </span>
                </p>
                <p>
                  <span className=" font-normal">{"WhatsApp Pack Charge"}</span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.whatsCharge} {" $"}
                  </span>
                </p>
                <p>
                  <span className=" font-normal">
                    {"Extra WhatsApp Messages Charge"}
                  </span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.additionalWhatsAppMsgCharges} {" $"}
                  </span>
                </p>
                <p>
                  <span className=" font-semibold">
                    {usesDetail?.totalCharges}
                  </span>
                  {" : "}
                  <span className=" font-semibold">
                    {usesDetail?.totalChargesValue} {" $"}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
        <hr className="my-8" />
        <h5 className="text-lg font-medium mb-5">All Plans</h5>
        <div className="shadow-card p-2 rounded-xl mb-10">
          <div className="flex justify-between pt-2">
            {tabInfo?.map((tab, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedTab(tab);
                }}
                className={`px-4 py-2 ${
                  selectedTab.title === tab.title &&
                  " text-white bg-secoundry px-2 rounded-lg"
                }`}
              >
                {tab.title}
              </div>
            ))}
          </div>
          {selectedTab.title === "Basic" && (
            <Plan
              planType={"Basic"}
              planInfo={pakaData?.basicPlan?.subHeading}
              planPrice={pakaData?.basicPlan?.price}
              planMin={pakaData?.basicPlan?.callDuration}
              planRate={pakaData?.basicPlan?.overDurationCharges}
              noOfBots={pakaData?.basicPlan?.noOfBots}
              newBotFixCharge={pakaData?.basicPlan?.newBotFixCharge}
              callHistoryValidity={pakaData?.basicPlan?.callHistoryValidity}
              messageCenter={pakaData?.basicPlan?.messageCenter}
              appointmentScheduling={pakaData?.basicPlan?.appointmentScheduling}
              whatsApp={pakaData?.basicPlan?.whatsApp}
              whatsAppIntegrationCharge={
                pakaData?.basicPlan?.whatsAppIntegrationCharge
              }
              whatsAppMessageCharge={pakaData?.basicPlan?.whatsAppMessageCharge}
              planButton={planButtonType?.basicText}
              btnStyle={
                planButtonType?.basicActive
                  ? `bg-primary text-white transition-all duration-300 ease-in-out hover:bg-secoundry hover:text-white hover:border-secoundry`
                  : `bg-[#E7E7E7] text-[#7F7F7F] opacity-50 cursor-not-allowed`
              }
              recStyle={`invisible`}
              boxStyle={`px-4`}
              isExtend={true}
              extendStyle={`hidden`}
              onChangePlan={() => handlePlanChange(852658)}
              updatePlanPopup={updatePlanPopupB}
              setUpdatePlanPopup={setUpdatePlanPopupB}
            />
          )}
          {selectedTab.title === "Advanced" && (
            <Plan
              planType={"Advanced"}
              planInfo={pakaData?.advancedPlan?.subHeading}
              planPrice={pakaData?.advancedPlan?.price}
              planMin={pakaData?.advancedPlan?.callDuration}
              planRate={pakaData?.advancedPlan?.overDurationCharges}
              noOfBots={pakaData?.advancedPlan?.noOfBots}
              newBotFixCharge={pakaData?.advancedPlan?.newBotFixCharge}
              callHistoryValidity={pakaData?.advancedPlan?.callHistoryValidity}
              messageCenter={pakaData?.advancedPlan?.messageCenter}
              appointmentScheduling={
                pakaData?.advancedPlan?.appointmentScheduling
              }
              whatsApp={pakaData?.advancedPlan?.whatsApp}
              whatsAppIntegrationCharge={
                pakaData?.advancedPlan?.whatsAppIntegrationCharge
              }
              whatsAppMessageCharge={
                pakaData?.advancedPlan?.whatsAppMessageCharge
              }
              planButton={planButtonType?.advancedText}
              btnStyle={
                planButtonType?.advancedActive
                  ? `bg-primary text-white transition-all duration-300 ease-in-out hover:bg-secoundry hover:text-white hover:border-secoundry`
                  : `bg-[#E7E7E7] text-[#7F7F7F] opacity-50 cursor-not-allowed`
              }
              recStyle={`invisible`}
              boxStyle={`px-4`}
              isExtend={true}
              extendStyle={`hidden`}
              onChangePlan={() => handlePlanChange(852659)}
              updatePlanPopup={updatePlanPopupA}
              setUpdatePlanPopup={setUpdatePlanPopupA}
            />
          )}
          {selectedTab.title === "Expert" && (
            <Plan
              planType={"Expert"}
              planInfo={pakaData?.expertPlan?.subHeading}
              planPrice={pakaData?.expertPlan?.price}
              planMin={pakaData?.expertPlan?.callDuration}
              planRate={pakaData?.expertPlan?.overDurationCharges}
              noOfBots={pakaData?.expertPlan?.noOfBots}
              newBotFixCharge={pakaData?.expertPlan?.newBotFixCharge}
              callHistoryValidity={pakaData?.expertPlan?.callHistoryValidity}
              messageCenter={pakaData?.expertPlan?.messageCenter}
              appointmentScheduling={
                pakaData?.expertPlan?.appointmentScheduling
              }
              whatsApp={pakaData?.expertPlan?.whatsApp}
              whatsAppIntegrationCharge={
                pakaData?.expertPlan?.whatsAppIntegrationCharge
              }
              whatsAppMessageCharge={
                pakaData?.expertPlan?.whatsAppMessageCharge
              }
              planButton={planButtonType?.expertText}
              recStyle={`invisible`}
              btnStyle={
                planButtonType?.expertActive
                  ? `bg-primary text-white transition-all duration-300 ease-in-out hover:bg-secoundry hover:text-white hover:border-secoundry`
                  : `bg-[#E7E7E7] text-[#7F7F7F] opacity-50 cursor-not-allowed`
              }
              boxStyle={`px-4`}
              isExtend={true}
              extendStyle={`hidden`}
              onChangePlan={() => handlePlanChange(852660)}
              updatePlanPopup={updatePlanPopupE}
              setUpdatePlanPopup={setUpdatePlanPopupE}
            />
          )}
        </div>
        <hr className="my-8" />
        <div
          onClick={() => {
            setIsShowMoreInfo(!isShowMoreInfo);
          }}
          className="flex justify-between"
        >
          <h5 className="text-lg font-medium mb-5">Payment & Subscription</h5>
          <div className="flex gap-2 mt-2 cursor-pointer">
            {!isShowMoreInfo && <AiOutlineDown />}
            {isShowMoreInfo && <AiOutlineUp />}
          </div>
        </div>
        {userData?.subscriptionStatus === "active" && isShowMoreInfo && (
          <div className="">
            <div className="flex justify-center mt-5">
              <a
                href={userData?.subscriptionUpdateUrl}
                className="bg-secoundry text-[14px] font-medium text-white rounded py-2 px-12 mt-6"
              >
                Edit payment method
              </a>
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => setShowModel(true)}
                className="bg-red-700 text-[14px] font-medium text-white rounded py-2 px-14 mt-6 mb-10"
              >
                {/* {userData?.requestCancelSubscription
                  ? "Re-Subscribe"
                  : "Cancel Subscription"} */}
                {"Cancel Subscription"}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PlanAndBilling;
